import React from "react"

import "./OfficeNew.scss"


export default function OfficeNew(){
  return(
    <div className="OfficeNew flex column">
      OfficeNew
    </div>
  )
}