import React from "react"


function Statistic() {
  return(
    <div className="flex column">

      <h2>Statistic</h2>

    </div>
  )
}

export default Statistic